<template>
  <div class="flex flex-col min-h-[100vh]">
    <Header />
    <main class="flex grow w-full justify-center">
      <slot />
      <Notifications :mapped-store="notieStore" />
    </main>
    <Footer class="rounded-t-[24px]" />
  </div>
  <LazyModalsBackCall />
</template>

<script setup>
import { useNotieStore } from '~/stores/notifications';

const notieStore = useNotieStore();
</script>

<style scoped></style>
